/**
 * Module dependencies.
 */

import { CSSProperties, useCallback, useMemo, useRef } from 'react';
import { DisclaimerSection } from 'src/components/sections/disclaimer';
import { LESSON_PROGRESS_KEY, LessonProgress } from 'src/types/local-storage';
import { LessonCtaSection } from './sections/cta';
import { LessonHero } from './hero';
import { LessonListSection } from './sections/list';
import { LessonPage as LessonPageProps } from 'src/api/entities/lessons/types';
import { LessonRichTextSection } from './sections/rich-text';
import { MobileScrollSpyWrapper } from 'src/components/core/scroll-spy/mobile-scroll-spy';
import { ScrollSpy } from 'src/components/core/scroll-spy';
import { WhatsNextSection } from 'src/components/sections/whats-next';
import { media } from 'src/styles/media';
import { sendGTMEvent, sendSnowplowEvent } from 'src/core/utils/analytics';
import { useActiveAnchor } from 'src/hooks/use-active-anchor';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useElementDimensions } from 'src/hooks/use-element-dimensions';
import { useLocalStorage } from 'src/hooks/use-local-storage';
import { useStoreScrollProgress } from 'src/hooks/use-store-scroll-progress';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: var(--color-background);
  display: grid;
  grid-template-areas:
    '.          content    .'
    'disclaimer disclaimer disclaimer'
    'next       next       next';
  grid-template-columns: 1fr min(100vw, var(--container-max-width)) 1fr;
`;

/**
 * `BackgroundWrapper` styled component.
 */

const BackgroundWrapper = styled.div`
  ::before {
    background-color: var(--color-background);
    content: '';
    height: var(--lesson-hero-height);
    inset: 0 0 auto;
    position: absolute;
  }
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-area: content;
  padding-left: var(--gutter);
  padding-right: var(--gutter);

  ${media.min.ms`
    --lesson-content-gap: 16px;

    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: var(--lesson-content-gap);
  `}

  ${media.min.md`
    --lesson-content-gap: 32px;

    padding-bottom: 48px;
  `}

  ${media.min.lg`
    --lesson-content-gap: 64px;
  `}

  ${media.min.xl`
    --lesson-content-gap: 96px;
  `}
`;

/**
 * `StyledScrollSpy` styled component.
 */

const StyledScrollSpy = styled(ScrollSpy)`
  ${media.max.md`
    padding: 0 0 16px;
  `}

  ${media.min.md`
    height: max-content;
    margin-right: var(--gutter-cards);
    max-height: calc(100vh - (var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--bottom-banner-height) + var(--gutter-navbar-y) * 3) - 32px);
    overflow-y: auto;
    position: sticky;
    top: calc((var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3));
    transition: 0.25s ease-out;
    transition-property: background-color, color, max-height;
    width: calc(100% - var(--gutter-cards));
  `}
`;

/**
 * `StyledDisclaimerSection` styled component.
 */

const StyledDisclaimerSection = styled(DisclaimerSection)`
  grid-area: disclaimer;
  padding: 0;
`;

/**
 * `StyledWhatsNextSection` styled component.
 */

const StyledWhatsNextSection = styled(WhatsNextSection)`
  grid-area: next;
`;

/**
 * Export `LessonPage` component.
 */

export const LessonPage = (props: LessonPageProps) => {
  const { course, courses, disclaimer, id, sections, subscription, whatsNextTitle } = props;
  const { id: courseId, lessons } = course;
  const lessonIndex = useMemo(() => lessons.findIndex(lesson => lesson.id === id), [lessons, id]);
  const courseIndex = useMemo(
    () => courses?.findIndex(newCourse => newCourse.id === courseId) ?? -1,
    [courses, courseId]
  );

  const anchorRefs = useRef<Array<HTMLAnchorElement | null>>([]);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [activeAnchor, isFirstVisible] = useActiveAnchor(anchorRefs, id);
  const [store] = useLocalStorage<LessonProgress>(LESSON_PROGRESS_KEY);
  const nextLessonId = lessons[lessonIndex + 1]?.id;
  const [ref, { height }] = useElementDimensions<HTMLDivElement>();
  const isDesktop = useBreakpoint('ms');
  const scrollSpyProps = useMemo(
    () => ({
      childIndex: activeAnchor,
      childNodes: sections
        .map(({ section }) => {
          if ('title' in section && section.title) {
            const label = section.title
              .trim()
              .replace(/<\/?[^>]+>/g, '')
              .replace(/[:.]$/g, '');

            return {
              href: `#${kebabCase(label)}`,
              label
            };
          }
        })
        .filter(Boolean) as Parameters<typeof ScrollSpy>[0]['childNodes'],
      selfIndex: lessonIndex,
      siblingNodes: lessons.map(({ id, title, url }) => ({
        completed: (store?.[courseId]?.[id]?.[0] ?? 0) >= 1,
        href: url,
        label: title
      }))
    }),
    [activeAnchor, courseId, lessons, lessonIndex, sections, store]
  );

  const checkTriggers = useCallback(
    (latest: number, progress: any, key: string, newValue: LessonProgress) => {
      if (latest === 1 && latest > (progress?.[0] || 0)) {
        sendGTMEvent('learning', 'lessonCompleted', key);
        sendSnowplowEvent('learning', 'lessonCompleted', { property: key });

        const trackedLessons = newValue[courseId] || {};

        if (lessons.every(({ id }) => trackedLessons[id]?.[0] === 1)) {
          sendGTMEvent('learning', 'courseCompleted', courseId);
          sendSnowplowEvent('learning', 'courseCompleted', { property: courseId });
        }
      }
    },
    [courseId, lessons]
  );

  useStoreScrollProgress<LessonProgress>({
    beforeSetValue: checkTriggers,
    key: `${courseId}.${id}`,
    parentRef: contentRef,
    rootKey: LESSON_PROGRESS_KEY,
    ...(nextLessonId && { nextKey: `${courseId}.${nextLessonId}` })
  });

  return (
    <Wrapper>
      <Content>
        <div ref={contentRef}>
          <BackgroundWrapper
            data-theme={'dark'}
            ref={ref}
            style={{ '--lesson-hero-height': `${height}px` } as CSSProperties}
          >
            <LessonHero {...props} index={lessonIndex} />
          </BackgroundWrapper>

          {sections.map(({ section, sectionType }, index) => {
            switch (sectionType) {
              case 'disclaimer_section':
                return <DisclaimerSection key={section.id} {...section} />;

              case 'lesson_list':
                return (
                  <LessonListSection
                    key={section.id}
                    ref={node => {
                      anchorRefs.current[index] = node;
                    }}
                    {...section}
                  />
                );

              case 'lesson_rich_text':
                return (
                  <LessonRichTextSection
                    key={section.id}
                    ref={node => {
                      anchorRefs.current[index] = node;
                    }}
                    {...section}
                  />
                );

              case 'lesson_cta':
                return <LessonCtaSection key={section.id} {...section} />;

              default:
                return null;
            }
          })}
        </div>

        {isDesktop && (
          <StyledScrollSpy {...scrollSpyProps} data-theme={isFirstVisible ? 'light' : 'dark'} title={course.title} />
        )}
      </Content>

      {isDesktop === false && (
        <MobileScrollSpyWrapper title={props.course.title}>
          <StyledScrollSpy {...scrollSpyProps} />
        </MobileScrollSpyWrapper>
      )}

      {!!disclaimer && <StyledDisclaimerSection {...disclaimer} />}

      <StyledWhatsNextSection
        subscription={subscription}
        title={whatsNextTitle}
        {...(!!courses[courseIndex + 1] && {
          course: courses[courseIndex + 1]
        })}
        {...(!!lessons?.[lessonIndex + 1] && {
          lesson: {
            ...lessons[lessonIndex + 1],
            index: lessonIndex + 2,
            progress: store?.[courseId]?.[lessons[lessonIndex + 1].id]?.[0],
            slug: lessons[lessonIndex + 1].url
          }
        })}
      />
    </Wrapper>
  );
};

/**
 * Module dependencies
 */

import 'swiper/css';
import { AppPage, GalleryAsset } from 'src/api/entities/apps/types';
import { CSSProperties, ComponentProps, useEffect, useMemo, useRef, useState } from 'react';
import { EffectCreative, Navigation, Thumbs } from 'swiper/modules';
import { FillVideo } from 'src/components/core/video';
import { IconButton } from 'src/components/core/buttons/icon-button';
import { Image } from 'src/components/core/image';
import { Svg } from 'src/components/core/svg';
import { Swiper, SwiperClass, SwiperSlide, useSwiperSlide } from 'swiper/react';
import { getFileType } from 'src/core/utils/files';
import { media } from 'src/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import arrowIcon from 'src/assets/svgs/24/arrow-right.svg';
import playIcon from 'src/assets/svgs/20/play.svg';
import styled from 'styled-components';

/**
 * `AssetProps` type.
 */

type AssetProps = Pick<GalleryAsset, 'asset'> & { style: CSSProperties };

/**
 * `SlideContainer` styled component.
 */

const SlideContainer = styled(Swiper)`
  ${media.max.ms`
    overflow: hidden;
  `}
`;

/**
 * `ThumbnailItemWrapper`  styled component.
 */

const ThumbnailItemWrapper = styled.div<{ isActive?: boolean }>`
  display: grid;
  height: 40px;
  place-items: center;
  position: relative;
  width: var(--gallery-thumbnail-width);
`;

/**
 * `ThumbnailsWrapper` styled component.
 */

const ThumbnailsWrapper = styled.div`
  overflow: visible;
  padding: 2px;
  position: relative;

  .swiper-slide-thumb-active img {
    border: 1px solid var(--color-neutral70);
  }

  ${media.min.ms`
    overflow: hidden;
  `}
`;

/**
 * `StyledSwiperSlide` styled component.
 */

const StyledSwiperSlide = styled(SwiperSlide)`
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-lightL);
  overflow: hidden;
`;

/**
 * `StyledThumbnail` styled component.
 */

const StyledThumbnail = styled(Image)`
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-lightM);
`;

/**
 * `NavigationWrapper` styled component.
 */

const NavigationWrapper = styled.div`
  padding-bottom: 80px;

  &[data-has-navigation='true'] {
    align-items: center;
    display: grid;
    gap: 8px;
    grid-template-areas: 'prev thumbs next';
    grid-template-columns: 1fr auto 1fr;
  }
`;

/**
 * `NavigationButton` styled component.
 */

const NavigationButton = styled(IconButton).attrs({
  icon: arrowIcon,
  size: 'large'
})`
  color: var(--color-neutral90);
  height: min-content;
  padding: 6px 8px;

  :disabled {
    background: transparent;
    color: var(--color-neutral10);
  }
`;

/**
 * `MobileScrollWrapper` styled component.
 */

const MobileScrollWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
  overflow-x: auto;
  width: 100%;
`;

/**
 * `carouselConfig` object.
 */

const carouselConfig = {
  creativeEffect: {
    limitProgress: 3,
    next: {
      opacity: 0.6,
      scale: 0.9,
      translate: ['98%', 0, -10]
    },
    prev: {
      opacity: 0.6,
      scale: 0.9,
      translate: ['-98%', 0, -10]
    }
  },
  effect: 'creative',
  modules: [EffectCreative, Thumbs],
  slideToClickedSlide: true,
  slidesPerView: 1
};

/**
 * `ImageSlide` component.
 */

function ImageSlide({ asset, ...rest }: AssetProps) {
  return <Image alt={asset?.alt || ''} fill src={asset?.url ?? ''} {...rest} />;
}

/**
 * `VideoSlide` component.
 */

function VideoSlide({ asset, style, ...videoProps }: AssetProps & ComponentProps<typeof FillVideo>) {
  const { isActive } = useSwiperSlide();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current?.[!isActive ? 'pause' : 'play']?.();
  }, [isActive, videoProps]);

  return (
    <FillVideo
      {...videoProps}
      autoPlay={false}
      controls={isActive}
      playsInline
      ref={videoRef}
      style={{ ...style, pointerEvents: isActive ? 'all' : 'none' }}
      tabIndex={isActive ? undefined : -1}
    >
      <source src={asset?.url} type={asset?.filetype} />
    </FillVideo>
  );
}

/**
 * Export `Carousel` component.
 */

export function Carousel({ gallery }: Pick<AppPage, 'gallery'>) {
  const isDesktop = useBreakpoint('ms');
  const gallerySize = useMemo(
    () => (!isDesktop ? gallery?.length : Math.min(5, gallery?.length)),
    [gallery, isDesktop]
  );

  const hasNavigation = gallerySize === 5 && isDesktop;
  const [mainSwiper, setMainSwiper] = useState<SwiperClass | null>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);

  return (
    <>
      <SlideContainer
        {...carouselConfig}
        onSwiper={setMainSwiper}
        onTransitionEnd={slide => {
          if (thumbsSwiper) {
            thumbsSwiper?.slideTo?.(slide.realIndex);
          }
        }}
        style={{
          height: isDesktop ? '560px' : '360px',
          overflow: 'visible',
          paddingBottom: '24px'
        }}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper?.destroyed ? thumbsSwiper : null }}
      >
        {gallery.map(({ asset, backgroundColor, id, objectFit }) => {
          const type = getFileType(asset?.filetype);
          const AssetElement = type === 'video' ? VideoSlide : ImageSlide;

          return !asset?.url || !type ? null : (
            <StyledSwiperSlide key={id}>
              <AssetElement
                asset={asset}
                style={{
                  ...(backgroundColor && { backgroundColor }),
                  objectFit
                }}
              />
            </StyledSwiperSlide>
          );
        })}
      </SlideContainer>

      {gallery.length > 1 && (
        <NavigationWrapper data-has-navigation={hasNavigation}>
          <NavigationButton
            onClick={() => mainSwiper?.slidePrev()}
            style={{
              display: hasNavigation ? 'block' : 'none',
              gridArea: 'prev',
              marginLeft: 'auto',
              transform: 'scaleX(-1)'
            }}
          />

          <NavigationButton
            onClick={() => mainSwiper?.slideNext()}
            style={{
              display: hasNavigation ? 'block' : 'none',
              gridArea: 'next'
            }}
          />

          <MobileScrollWrapper>
            <ThumbnailsWrapper
              style={{ width: `calc(var(--gallery-thumbnail-width) * ${gallerySize} + 8px * ${gallerySize - 1})` }}
            >
              <Swiper
                centeredSlides={!!hasNavigation}
                centeredSlidesBounds
                modules={[Navigation]}
                onSwiper={setThumbsSwiper}
                slidesPerView={gallerySize}
                spaceBetween={8}
                style={{ gridArea: 'thumbs', overflow: 'visible' }}
              >
                {gallery.map(({ asset, id, thumbnail }) => (
                  <SwiperSlide key={id}>
                    <ThumbnailItemWrapper>
                      <StyledThumbnail
                        alt={thumbnail?.alt || ''}
                        fill
                        src={thumbnail?.url || ''}
                        style={{ objectFit: 'cover' }}
                      />

                      {getFileType(asset?.filetype) === 'video' && (
                        <Svg color={'var(--color-white)'} icon={playIcon} size={'24px'} />
                      )}
                    </ThumbnailItemWrapper>
                  </SwiperSlide>
                ))}
              </Swiper>
            </ThumbnailsWrapper>
          </MobileScrollWrapper>
        </NavigationWrapper>
      )}
    </>
  );
}
